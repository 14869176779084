export const convertEmailJobIDToS3Obj = (email, jobId, cvExtension) => {
    const transferedEmail = email.replaceAll("@", "").replaceAll(".", "")
    return `${transferedEmail}_${jobId}${cvExtension}`
}

export const sanitizeCsvCell = (text) => {
    try {
        if ([null, undefined, ''].includes(text)){
            return ''
        }
        if(text instanceof Object){
            return ''
        }
        let sanitizedCell = String(text)
        sanitizedCell = sanitizedCell.replace(/“|”/g, '"');
        sanitizedCell = sanitizedCell.replace(/"/g, '""');
        // If the cell contains commas, double quotes, or newlines, wrap it in double quotes
        if (
            sanitizedCell.includes(',') || 
            sanitizedCell.includes('"') || 
            sanitizedCell.includes('\n') || 
            sanitizedCell.includes('\r')) 
        {
            sanitizedCell = `"${sanitizedCell}"`;
        }
        
        return sanitizedCell;
    } catch (error) {
        return ''
    }
} 