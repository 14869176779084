import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from "@material-ui/core/Box";
import CardMedia from '@material-ui/core/CardMedia';
import PropTypes from "prop-types";
import { setLanguage } from "redux-i18n"
import { connect } from "react-redux";
import { setLanguagePref } from '../../slices/preference';
import { setLoginRole } from '../../slices/preference';
import { ROLE } from "../../constants/constants";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import config from '../../config'
import size from 'lodash/size'
import { withRouter } from "react-router-dom";
import { LANGUAGES_DROPDOWN_CODES } from '../../constants/constants';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    color: '#FFF',
    backgroundColor: '#FFF'
  },
  headerItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  media: {
    backgroundSize: 'contain',
    width: 160,
    height: 45, // 16:9
  },
}));


export const renderLanguageOptions = languages => {
  const m = LANGUAGES_DROPDOWN_CODES
  return languages.filter(x => x in m).map(x => (<option key={x} value={x}>{m[x]}</option>))
}


export const __LanguageSelectionMenu = ({
  setLanguage,
  setLanguagePref,
  lang,
}, context) => {
  const classes = useStyles();
  console.log("__LanguageSelectionMenu: setLanguage: ", setLanguage, " setLanguagePref: ", setLanguagePref, " lang: ", lang)
  if(lang){
    setLanguagePref(lang.replace('_color', ''))
    setLanguage(lang.replace('_color', ''))
  }

  const handleLanguageChange = event => {
    setLanguage(event.target.value);
    setLanguagePref(event.target.value);
  };
  
  return size(config.languages) > 1 ?
    (<NativeSelect className={classes.headerItem} value={lang} onChange={handleLanguageChange}> 
      {/*<option value="" disabled>
        {context.t('MainMenu_Language')}
      </option>*/}
      {renderLanguageOptions(config.languages)}
    </NativeSelect>) : null
}

__LanguageSelectionMenu.contextTypes = {
  t: PropTypes.func
};

const __LanguageSelectionMenu_mapState = state => ({
  lang: state.preference.language,
})

const __LanguageSelectionMenu_mapDispatch = {
  setLanguage,
  setLanguagePref,
}

const LanguageSelectionMenu = connect(
  __LanguageSelectionMenu_mapState,
  __LanguageSelectionMenu_mapDispatch,
)(__LanguageSelectionMenu)


// eslint-disable-next-line no-empty-pattern
const MainMenu = ({
  setLoginRole,
  history,
}, context) => {
  const classes = useStyles();
  if (history.location.state?.prePath) return <></>

  const handleRoleChange = value => {
    setLoginRole(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          <Box className={classes.title}>
            <CardMedia className={classes.media} image={config.horizontalLogo} />
          </Box>
          <LanguageSelectionMenu />

          <Button href="/candidate" variant='outlined' onClick={() => { handleRoleChange(ROLE.jobseeker); }}>
            {context.t("MainMenu_CandidateLogin")} <ChevronRightIcon />
          </Button>

        </Toolbar>
      </AppBar>
    </div>
  );
};

MainMenu.contextTypes = {
  t: PropTypes.func
};

const mapState = state => {
  return {
    role: state.authenticated.role
  }
};

const mapDispatch = {
  setLoginRole,
};

export default withRouter(connect(mapState, mapDispatch)(MainMenu));
