import { createSlice } from 'redux-starter-kit'

import {
  createGroup,
  fetchGroups,
  fetchRecruiter,
  extract_token,
  register_by_token,
  add_recruiter,
  edit_recruiter,
  confirm_status,
  fetch_group_program,
  delete_recruiter,
  delete_group,
  edit_group,
  generateGroupAPIKey,
} from '../apis/group'

let initialState = {
  groups: [
    {
      groupName: '',
      gid: '',
      billingAdmin: {
        email: '',
        status: ''
      },
      admin: [],
      recruiter: []
    }
  ],
  recruiterList: [],
  programList: [],
  availableProgrammes: []
}

const groupSlice = createSlice({
  slice: 'group',
  initialState: initialState,
  reducers: {
    onGettingGroupInfo (state, action) {
      state.groups = action.payload
    },
    onGettingRecruiterInfo (state, action) {
      state.recruiterList = action.payload
    },
    onGettingGroupProgram (state, action) {
      state.programList = action.payload
    },
    onGettingAvailableProgrammes (state, action){
      console.log("fetchRecruiterGroups: action.payload: ", action.payload)
      state.availableProgrammes = action.payload
    }
  }
})

export const fetchRecruiterGroups = token => async dispatch => {
  try {
    const res = await fetchGroups(token)
    if (res.obj.error_code === 0){
      dispatch(onGettingGroupInfo(res.obj.message))
      let returnObj = res.obj.message
      let result = []
      returnObj.map( groupInfo => {
        if ("program" in groupInfo){
          groupInfo.program.map( programData => {
            result.push(programData)
          })
        }
      })
      console.log("fetchRecruiterGroups: result: ", result)
      dispatch(onGettingAvailableProgrammes(result))
    } 
  } catch (err) {
    console.log(err)
  }
}

export const createGroups = req => async dispatch => {
  try {
    const res = await createGroup(...req)
    return res
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}
export const getGroupsRecruiter = req => async dispatch => {
  try {
    const res = await fetchRecruiter(...req)
    console.log(res, 'result')
    if (res.obj.error_code == 0)
      dispatch(onGettingRecruiterInfo(res.obj.message))
    return res
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const getEmailFromToken = token => async dispatch => {
  
  // Default response
  let response = {
    email: null,
    error_code: 9,
    message: "Unknown error"
  }

  try {
    const res = await extract_token(token)
    // Valid token for registration
    if (res.obj?.error_code == 0) {
      response.email = res.obj.msg
      response.error_code = 0
    }
    else if (res.obj?.error_code == 1) {
      response.error_code = 1
      response.message = "Token expired"
    }
    else if (res.obj?.error_code == 2) {
      response.error_code = 2
      response.message = "Token invalid"
    }
    else if (res.obj?.error_code == 4) {
      response.error_code = 4
      response.message = "The link has been cancelled by the host user"
    } else if (res.obj?.error_code == 10) {
      response.error_code = 10
      response.message = "The recruiter has already been registered"
    }
      console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return response

}

export const register_recruiter = args => async dispatch => {
  try {
    const res = await register_by_token(...args)

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const inviteRecruiter = args => async dispatch => {
  try {
    const res = await add_recruiter(...args)
    return res.obj
  } catch (err) {
    console.log(err, 'result')
    return null
  }
  return 'ans'
}

export const edit_recruiter_role = args => async dispatch => {
  try {
    const res = await edit_recruiter(...args)

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const confirm_adminBilling_role = args => async dispatch => {
  try {
    const res = await confirm_status(...args)
    

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const getGroupProgrammeStatistics = token => async dispatch => {
  try {
    const statResponses = await fetch_group_program(token)
    const payload = []
    if (statResponses.ok && statResponses.obj && statResponses.obj.result) {
      statResponses.obj.result.forEach(statResponse => {
        payload.push({
          jobId: statResponse.job_id,
          numOfCandidates: statResponse.total_candidates,
          deadline: statResponse.interview_deadline.split(' ')[0] || '-',
          completion: statResponse.completed_candidates,
          reviewed: statResponse.ai_reviewed_candidates,
          questionNum: statResponse.num_of_question,
          completionRate:
            statResponse.total_candidates && statResponse.total_candidates > 0
              ? Math.round(
                  (statResponse.ai_reviewed_candidates /
                    statResponse.total_candidates) *
                    100
                )
              : 0,
          gid: statResponse.gid,
          groupName: statResponse.groupName,
          test: statResponse.test,
          program_name: statResponse.program_name,
          job_name: statResponse.job_name,
          created_at: statResponse.created_at,
          archived: statResponse.archived,
          canArchive: statResponse?.can_archive,
          source: statResponse?.groupName || "",
        })
      })
    }
    dispatch(onGettingGroupProgram(payload))
  } catch (e) {
    console.log(e)
  }
}

export const confirm_delete_recruiter = args => async dispatch => {
  try {
    const res = await delete_recruiter(...args)
    

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const confirm_delete_group = args => async dispatch => {
  try {
    const res = await delete_group(...args)

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const confirm_edit_group = args => async dispatch => {
  try {
    const res = await edit_group(...args)

    if (res.obj.error_code == 0) return res.obj.msg
    else return res.obj.error_code
    console.log(res, 'result')
  } catch (err) {
    console.log(err, 'result')
  }
  return 'ans'
}

export const generateAPIKey = (token, gid) => async dispatch => {
  try {
    const res = await generateGroupAPIKey(token, gid);
    if (res.ok && res.obj.error_code === 0) {
      console.log(res.obj.message)
      return res.obj
    } else {
      console.log("Error with api call (ok)")
      return ""
    }
  } catch (err) {
    console.log(err);
    return "";
  }
}

export const {
  onGettingGroupInfo,
  onGettingRecruiterInfo,
  onGettingGroupProgram,
  onGettingAvailableProgrammes,
} = groupSlice.actions

export default groupSlice.reducer
