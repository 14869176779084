import moize from 'moize'
import clientRequest from "../constants/clientRequest"

const tag = 'api/questions';

const __getQuestions = (soft_skill, model, token) => {
    
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_fetch_questions({
                payload: {
                    soft_skill,
                    token,
                    model
                }
            });
        })
};
export const getQuestions = moize( __getQuestions, { maxAge: 5 * 60 * 1000 } )

const questions = {
    getQuestions
};

export default questions;
