import React,{ useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";

import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";


// Microsoft Authentication Library prerequisites
import { 
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
    useMsal,
    useIsAuthenticated,
} from "@azure/msal-react";

import { 
    InteractionType,
    InteractionRequiredAuthError,
    BrowserAuthError,
    InteractionStatus,
    ServerError,
} from "@azure/msal-browser";

// Material UI
import { makeStyles } from '@material-ui/core/styles';

// import Paper from "@mui/material/Paper";
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from "@material-ui/icons/Help";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// Loading overlay
import LoadingOverlay from 'react-loading-overlay';

// Utility APIs
import { callMsGraph } from '../../utils/MSGraphCall';
import { loginSso } from "../../slices/authentication";
import { setLoginRole, setIdentityProvider } from '../../slices/preference';

// Configs
import { ROLE } from "../../constants/constants";
import { loginRequest } from '../../config/authConfig.dev'
import { Button, DialogContentText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        // maxWidth: '1072px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(10, 4),
        [theme.breakpoints.down('sm')]: {
            // padding: theme.spacing(4, 2)
            padding: '45px 16px'
        },
    },
    appBar: {
        //backgroundColor: props => props.bgColor,
        background: `linear-gradient(to right, white 30%, ${theme.palette.primary.main})`
    },
    dilogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginBottom: 16,
        fontSize: 24,
    },
    dialogContent: {
        color: theme.palette.primary.main,
        fontSize: 20,
        position:'center',
        textAlign: 'center',
    },
    dialogButton: {
        backgroundColor: theme.palette.primary.main,
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        textAlign: 'right',
        background: theme.palette.common.white
    },
    logoContainer2: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        textAlign: 'left',
        width: '100%',
    },
    logoContainer3: {
        backgroundColor: 'transparent'
    },
    help: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        width: 40,
        height: 40,
        marginRight: theme.spacing(2),
    },
    helplarge: {
        background: "#fff",
        position: "absolute",
        right: "60px",
        margin: theme.spacing(2),
    },
    exitlarge: {
        background: '#fff',
        position: "absolute",
        right: '6px',
    },
    media: {
        width: 'auto',
        height: '64px',
    },
    headerMessage: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
        }
    },
    paper: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        width: theme.spacing(92),
        padding: theme.spacing(5, 3),
        '& p': {
            fontSize: theme.typography.body1.fontSize,
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            borderRadius: '13px',
            '& p': {
            fontSize: theme.typography.caption.fontSize,
            },
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const SsoLogin = ({history, emailId, identityProvider, companyLogo, language}, context) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const handleRoleChange = value => {
        dispatch(setLoginRole(value));
    };
    /**
     * As of 6th June 2024
     * SSO Login is only available for recruiter accounts
     */
    handleRoleChange(ROLE.recruiter)

    const requestBody = {
        ...loginRequest,
        authority: 'https://login.microsoftonline.com/b3e19ac2-e192-44c8-90bd-41acbfb3dcdb/',
        // authority: 'https://adfs.polyu.edu.hk/adfs',
        redirectUri: "https://interview-uat.neufast.com/sso",
    }
    const [loginData, setLoginData] = useState(null)
    const { instance, accounts, inProgress } = useMsal();
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, requestBody)
    const isAuthenticated = useIsAuthenticated();

    const [requesting, setRequesting] = useState(false);

    const [popMessage, setPopMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const [loadingValue, setLoadingValue] = useState({
        previousStep: null,
        value: 0,
    })

    const updateLoading = (step, steps) => {
        console.log("loadingValue: step: ", step)
        setLoadingValue(prev => {
            if(prev.previousStep !== step){
                const newVal = prev.value >= 100 ? 0 : (prev.value + (100/steps))
                console.log("loadingValue: value: ", newVal)
                return {
                    ...prev,
                    previousStep: step,
                    value: newVal,
                }
            } else {
                return prev
            }
        })
        // setLoadingValue(prev => (prev >= 100 ? 0 : prev + (100/steps)))
    }

    useEffect(() => {
        console.log("loadingValue: prev: ", loadingValue.previousStep, " value: ", loadingValue.value)
    }, [loadingValue])

    const callLogin = (email, claims, environment, accessToken) => {
        console.log("SsoLogin: calling api: callLogin: email: ", email," claims: ", claims, " environment: ", environment ," accessToken: ", accessToken)
        setRequesting(prev => true)
        dispatch(loginSso(email, claims, environment, accessToken, ROLE.recruiter, identityProvider)).then((returnObj) => {
            if(returnObj && returnObj.token){
                setRequesting(prev => false)
                if (returnObj.authPath){
                    history.push(returnObj.authPath);
                } else {
                    history.push('/')
                }
            } else {
                console.log("ssoLogin: returnObj when failed:", returnObj)
                setRequesting(prev => false)
                setPopMessage(prev => true)
                setErrorMessage(prev => returnObj.message)
            }
        })
    }

    const openWindow = () => {
        // window.open("https://polyuelc.neufast.com/candidate/support");
        // window.open("https://polyuelc.neufast.com/candidate/support");
        history.push("/support")
    };
    
    const handleIconOnClick = () => {
        history.push('/')
    }

    const goBack = () => {
        history.push('/')
    }

    const retryLogin = () => {
        setPopMessage(prev => false)
        setErrorMessage(prev => null)
        login(InteractionType.Redirect, {
            ...requestBody,
            prompt: 'select_account',
        })
    }

    const max_steps = 3
    useMemo(() => {
        // Loading Steps
        if(inProgress === 'startup'){
            updateLoading('startup', max_steps)
        }
        if (isAuthenticated === true){
            updateLoading('authentication', max_steps)
        }
        if (result !== null){
            updateLoading('result', max_steps)
        }
    }, [inProgress, isAuthenticated, result])

    useEffect(() => {
        console.log(
            "SsoLogin: instance: ",
            // instance,
            "\n accounts: ",
            accounts,
            "\n inProgress: ",
            inProgress,
            "\n isAuthenticated: ",
            isAuthenticated,
            "\n result",
            result,
            "\n error",
            error
        );

        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError){
            console.log("Error with interaction")
            if(inProgress === InteractionStatus.None){
                login(InteractionType.Redirect, {
                    ...requestBody,
                    prompt: 'select_account'
                })
            }
            return;
        }

        if (error instanceof ServerError){
            console.log("Server declined")
            setPopMessage(prev => true)
            setErrorMessage(prev => error.message)
        }

        if(result && isAuthenticated && inProgress === InteractionStatus.None){
            console.log("SsoLogin: Result from SSO Login: ", result)
            setRequesting(prev => true)
            let accounts = instance.getAllAccounts()
            let account = instance.getActiveAccount()
            if (!account && accounts.length > 0){
                console.log("Setting active account to first available account")
                instance.setActiveAccount(accounts[0])
            }
            callMsGraph()
            .then(({return_val, return_token}) => {
                setRequesting(prev => false)
                console.log(
                    "SsoLogin: token acquisition: retrieving profile info: ",
                    return_val,
                    " accessToken:",
                    return_token,
                );
                setLoginData(prev => return_val);
                callLogin(return_val["username"], return_val["idTokenClaims"], return_val["environment"], return_token)
            })
            .catch(error => {
                setRequesting(prev => false)
                console.log(
                    "SsoLogin: error",
                    error
                )
                const activeAccount = instance.getActiveAccount()
                console.log(
                    "SsoLogin: token acquisition: catch: retrieving profile info: ",
                    activeAccount
                );
                setLoginData(prev => activeAccount)
                callLogin(activeAccount["username"], activeAccount["idTokenClaims"], activeAccount["environment"], result["accessToken"])
            })
            }
        return () => {
            setLoadingValue(prev => 0)
        }
    }, [result, error, isAuthenticated, inProgress])

    return(
        <>
        <AppBar position="static" className={classes.appBar} role="ssologin-appbar">
            <Toolbar disableGutters={true}>
            <Box className={classes.logoContainer}>
                {companyLogo && <CardMedia className={classes.media} src={companyLogo} component={'img'} onClick={handleIconOnClick}/>}
            </Box>
            <IconButton
                onClick={openWindow}
                className={(classes.help, classes.helplarge)}
            >
                <HelpIcon />
            </IconButton>
            <IconButton
                onClick={goBack}
                className={(classes.exit, classes.exitlarge)}
            >
                <ExitToAppIcon />
            </IconButton>
            </Toolbar>
        </AppBar>

        <Container relative className={classes.root} role='ssologin-root-container'>
            <Dialog
                role='ssologin-dialog'
                fullWidth={true}
                maxWidth='sm'
                open={popMessage}
            >
                <DialogTitle className={classes.dilogTitle}>Please give login details... </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContent}>
                        {errorMessage} 
                    </DialogContentText>
                    <DialogContentText className={classes.dialogContent}>
                        {context.t("SsoLogin_DialogBox_Text_Content_Failed")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.dialogButton} onClick={retryLogin} color="secondary" variant="contained">
                        {context.t("SsoLogin_DialogBox_Button_Retry")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography className={classes.headerMessage} align="center" variant={'h4'}>
                {context.t("SsoLogin_Welcome_Message")}
            </Typography>
            <Paper elevation={16} className={classes.paper}>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                    <Grid item xs={12}>
                    <AuthenticatedTemplate>
                        <Typography align='center' variant='h6'>
                            {loginData && loginData.username ? context.t("SsoLogin_Welcome_Message_Identity", {email: loginData.username}) : context.t("SsoLogin_Welcome_Message_Identity", {email: "recruiter"})}
                            {errorMessage ? context.t("SsoLogin_Welcome_Message_Error_Message_Authenticated", {errorMessage: errorMessage}) : null}
                            {/* {loginData && loginData.username ? `Welcome ${loginData.username} We are logging you in...`: `Welcome !! We are logging you in...`} 
                            {errorMessage ? `Error : \n${errorMessage}` : null} */}
                        </Typography>
                        { requesting ? <CircularProgress /> : null}
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Typography align='center' variant='h6'>
                            {identityProvider ? context.t("SsoLogin_Welcome_Message_Identity_Provider", {identityProvider: identityProvider}) : null}
                            {/* {identityProvider ? `Using ${identityProvider} SSO` : null} */}
                        </Typography>
                        <Typography align='center' variant='h6'>
                            {error && error.message ? context.t("SsoLogin_Welcome_Message_Error_Message_Unauthenticated", {errorMessage: error.message}) : null }
                            {/* {error && error.message ? `Error: ${error.message}. Redirecting...` : null } */}
                        </Typography>
                    </UnauthenticatedTemplate>
                    </Grid>
                    {
                        loadingValue.value!== 0 ? (
                            <Grid item xs={12}>
                                <CircularProgress
                                    variant='determinate'
                                    value={loadingValue.value}
                                />
                            </Grid>
                        ) : null
                    }
                </Grid>
            </Paper>
        </Container>
        </>
    )
}

const mapState = state => {
    return {
        companyLogo: state.interview.companyLogo,
        identityProvider: state.preference.identityProvider,
        language: state.preference.language,
    }
}

SsoLogin.contextTypes = {
    t: PropTypes.func,
};

export default withRouter(connect(mapState, null)(SsoLogin))