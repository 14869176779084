import MainMenu from "../components/menu/RecruiterMainMenu";
import React from "react";
import Login from "../components/login/Login";


function Home() {
  return (
    <React.Fragment>
      <MainMenu></MainMenu>
      <Login/>
    </React.Fragment>
  )
}

export default Home;
