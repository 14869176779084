import Swagger from 'swagger-client'
import uuidv1 from "uuid/v1";
import _ from "lodash";
import config from '../config'
import axios from 'axios'
import moize from 'moize'
import clientRequest from "../constants/clientRequest"
import { upload_single_object_to_s3 } from '../utils/s3_single_upload'
import { BUCKETS } from '../constants/constants';
import { parse } from 'date-fns';

const tag = 'api/recruiter';
const tag_candidate_report = 'api/candidate/report';

export const login = (email, password, rememberMeToken) => {
  return clientRequest
    .then(client => {
      // Tags interface
      return client.apis[tag].post_recruiter_login({
        payload: {
          email,
          password,
          rememberMeToken
        }
      });
    })
};

export const loginSso = (claims, environment, accessToken, identityProvider) => {
  // let tempResponse;
  // tempResponse.status = 'success'
  // tempResponse.token = ""
  // tempResponse = {
  //   recruiter: {
  //     login: '/recruiter/settings',
  //     logout: '/',
  //   },
  // }
  // return tempResponse
  return clientRequest.then( client => {
    return client.apis[tag].post_recruiter_login_sso({
      payload: {
        claims: claims,
        environment: environment,
        access_token: accessToken,
        identity_provider: identityProvider,
      },
  })
  })
}

export const loginSsoTokens = (tenantConfigs, accessToken, idToken) => {
  return clientRequest.then( client => {
    return client.apis[tag].post_recruiter_login_sso_tokens({
      payload: {
        tenant_configs: tenantConfigs,
        access_token: accessToken,
        id_token: idToken,
      }
    })
  })
}

export const logout = (token) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_recruiter_logout({
      payload: {
        token,
      }
    });
  })
};

export const register = (token, email, first_name, last_name, password, evaluation_required, video_annotation_required) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_register_activate({
      payload: {
        token,
        email,
        first_name,
        last_name,
        password,
        evaluation_required,
        video_annotation_required,
      }
    })
  })
}

export const changePassword = (existing_password, new_password, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_change_password({
      payload: {
        existing_password,
        new_password,
        token,
      }
    })
  })
}

export const forgetPassword = (email) => {
  return clientRequest.then(client => {
    // Tags Interface
    return clientRequest.then(client => {
      // Tags Interface
      return client.apis[tag].post_sendgrid_forget_password({
        payload: {
          email,
        }
      })
    })
  })
}

export const resetPassword = (new_password, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_reset_password({
      payload: {
        new_password,
        token,
      }
    })
  })
}

export const getPassPolicy = (token, authorizedSetting) => {
  return clientRequest.then(client => {
    // Tags Interface
    const formData = new FormData();
    formData.append("authorizedSetting", authorizedSetting);
    let headers = {
      'Authorization': `Bearer ${token}`
    }
    const request = {
      url: `${config.baseUrl}/api/recruiter/get_passPolicy`,
      method: 'POST',
      body: formData,
      headers: headers,
    }
    return Swagger.http(request)
  })
}

export const updateInformation = (token, payload) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_update_information({
      payload: {
        token,
        ...payload
      }
    })
  })
}

export const updateAccountInformation = (token, payload) => {
  console.log(payload)
  return clientRequest.then(client => {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("first_name", payload.firstName);
    formData.append("last_name", payload.lastName);
    formData.append("job_title", payload.jobTitle);
    formData.append("phone_num", payload.phoneNum);
    formData.append("email", payload.email);
    formData.append("company_name", payload.companyName);
    formData.append("company_website", payload.companyWebsite);
    formData.append("timezone", payload.timezone);
    formData.append("timezone_area", payload.timezoneArea);
    formData.append("evaluation_required", payload.evaluationRequired ? 1 : 0)
    formData.append('video_annotation_required', payload.videoAnnotationRequired ? 1 : 0)
    
    if (payload.source == "sap"){
      formData.append("source",payload.source)
      formData.append("sapCompanyUrl",payload.sapCompanyUrl)
      formData.append('sapUsername',`${payload.sapUsername}@${payload.sapCompanyId}`)
      formData.append('sapPassword',payload.sapPassword)
      formData.append('sapStatusId',payload.sapStatusId)
    }

    if (payload.profilePicture && (payload.profilePicture instanceof Blob)) {
      formData.append("skip_picture", 'no');
      //formData.append("profile_picture", payload.profilePicture, `profilePicture-${uuidv1()}${payload.profilePictureExtension}`);
      let profile_pic_name = `interviewer_images/profilePicture_${uuidv1()}${payload.profilePictureExtension}`
      formData.append("profile_picture", profile_pic_name) //`company_videos/companyVideo-${jobId}-${uuidv1()}${layout.companyVideoExtension}`);
      let profile_pic_file = payload.profilePicture
      upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, profile_pic_file, profile_pic_name, token)

    } else if (payload.profilePicture) {
      formData.append("skip_picture", 'yes');
    } else {
      formData.append("skip_picture", 'no');
    }

    if (payload.companyLogo && (payload.companyLogo instanceof Blob)) {
      formData.append("skip_logo", 'no');

      // uploading company logo
      let logo_name = `company_logos/companyLogo-${uuidv1()}${payload.companyLogoExtension}`
      formData.append("company_logo", logo_name);

      let logo_file = payload.companyLogo
      upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, logo_file, logo_name, token)


    } else if (payload.companyLogo) {
      formData.append("skip_logo", 'yes');
    } else {
      formData.append("skip_logo", 'no');
    }
    

    if (payload.companyVideo && (payload.companyVideo instanceof Blob)) {
      formData.append("skip_video", 'no');
      //formData.append("company_video", payload.companyVideo, `companyVideo-${uuidv1()}${payload.companyVideoExtension}`);
      // uploading company logo
      let company_video_name = `company_videos/companyLogo-${uuidv1()}${payload.companyVideoExtension}`
      formData.append("company_video", company_video_name);

      let company_video_file = payload.companyVideo
      upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, company_video_file, company_video_name, token)

    } else if (payload.companyVideo) {
      formData.append("skip_video", 'yes');
    } else {
      formData.append("skip_video", 'no');
    }
    formData.append("developerModeEnabled", payload?.developerModeEnabled ? 1 : 0);
    if (payload.passlen){
      formData.append("passlen", payload.passlen);
      formData.append("strength",JSON.stringify(payload.strength));
      formData.append("other", JSON.stringify(payload.other));
    }

    const request = {
      url: `${config.baseUrl}/api/recruiter/update_account_information`,
      method: 'POST',
      body: formData,
    };
    return Swagger.http(request)
  });
};

export const generateRecruiterAPIKey = (token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_generate_api_key({
      payload: {
        token,
      }
    })
  })
}

export const verify_sap_account_api = (token,payload)=>{
  return clientRequest.then(client=>{
    return client.apis[tag].post_sap_verify
      ({payload:{
        token,
        ...payload
      }})
  })
}

export const updateAnnotationApi = (token, payload) => {
  console.debug(payload)
  return clientRequest.then(client => {
    const formData = new FormData();
    formData.append("token", token);
    formData.append('video_annotation_required', payload.videoAnnotationRequired ? 1 : 0)
    const request = {
      url: `${config.baseUrl}/api/recruiter/update_account_information`,
      method: 'POST',
      body: formData,
    };
    return Swagger.http(request)
  });
};


export const action = (action, job_id, interview_ids, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_actions({
      payload: {
        action,
        job_id,
        interview_ids,
        token,
      }
    })
  })
}

export const sapStatusChangeAPI = (newStatus, job_id, interview_ids, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_sap_status_change({
      payload: {
        newStatus,
        job_id,
        interview_ids,
        token
      }
    })
  })
}

export const sapStatusResetAPI = (job_id, jobseeker_emails, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_sap_status_reset({
      payload: {
        job_id,
        jobseeker_emails,
        token
      }
    })
  })
}

export const reportAction = (lang, job_id, _interview_id, candidate_report_type, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    const interview_id = _interview_id[0]
    return client.apis[tag_candidate_report].post_get_candidate_pdf_report({
      payload: {
        job_id,
        interview_id,
        lang,
        candidate_report_type,
        token,
      }
    })
  })
}

export const addProgram = (program, token, job_id_to_copy) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_add_program({
      payload: {
        program,
        token,
        job_id_to_copy,
        current_domain: window.location.origin,
      }
    })
  })
}

export const updateProgram = (program, job_id, token) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_update_program({
      payload: {
        program,
        job_id,
        token
      }
    })
  })
} 

export const updateCandidateInformation = (token, interview_id, new_info) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_change_jobseeker_information({
      payload: {
        token,
        new_info,
        interview_id
      }
    })
  })
}

export const setLayout = (token, jobId, layout, onUploadProgress, interviewModel) => {
  // Tags Interface
  const formData = new FormData();
  // console.log(layout, "++++++++")
  formData.append("token", token);
  formData.append("interview_language", layout.interviewLanguage);
  formData.append("company_name", layout.companyName);
  formData.append("company_website", layout.companyWebsite);
  formData.append("company_message", layout.companyMessage);
  formData.append("timezone", layout.timezone);
  // formData.append("interview_start", layout.startDate);
  // formData.append("interview_deadline", layout.endDate);
  formData.append("question_length", layout.questionLength)
  formData.append('preparation_time', layout.preparationTime)
  formData.append("colour_scheme", layout.colorScheme);
  formData.append("job_id", jobId);
  // Upload CV feature:
  formData.append("candidate_upload_cv_required", layout.candidate_upload_cv_required ? 1 : 0)
  formData.append("arator", layout.avatar === false ? 'no' : 'yes');
  formData.append("use_virtual_avatar", layout.useVirtualAvatar)
  formData.append("virtual_avatar", layout.virtualAvatar)
  formData.append("show_avatar_identity", layout.avatar && !layout.useVirtualAvatar && layout.showAvatarIdentity ? 'yes' : 'no');
  formData.append("avatar_information", JSON.stringify(layout.interviewer));
  
  // Magic Link (QRCode/URL Link)
  formData.append("qrCodeEnabled", layout.qrCodeEnabled ? 1 : 0)
  // formData.append("magicURL", layout.magicURL ? layout.magicURL : "")
  // formData.append("deadlineSet", layout.deadlineSet ? layout.deadlineSet : false)
  
  if (interviewModel) formData.append("interviewModel",interviewModel)

  if (layout.avatar && layout.showAvatarIdentity && !layout.useVirtualAvatar && layout.interviewerImage && (layout.interviewerImage instanceof Blob)) {
    let obj_name = `interviewer_images/interviewerImage-${jobId}${layout.interviewerImageExtension}`
    formData.append("interviewer_image", obj_name)
    // uploading object to s3
    let obj = layout.interviewerImage
    upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, obj, obj_name, token)
  }else if (layout.interviewerImage !== false){
    formData.append("interviewer_image", '')
  }

  formData.append("layout_type", layout.layoutType)
  formData.append("consentEnabled", layout.consentEnabled ? 1 : 0)

  if (layout.timezoneArea) {
    formData.append("timezone_area", layout.timezoneArea);
  }

  if (layout.companyLogo && (layout.companyLogo instanceof Blob)) {
    formData.append("skip_logo", 'no');
    let obj_name = `company_logos/companyLogo-${jobId}${layout.companyLogoExtension}`
    // console.log("company_logo", obj_name)
    formData.append("company_logo", obj_name)
    // uploading object to s3
    let obj = layout.companyLogo
    upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, obj, obj_name, token)
  } else if (layout.companyLogo || layout.companyLogo === false) {
    formData.append("skip_logo", 'no');
  } else {
    formData.append("skip_logo", 'yes');
    formData.append("company_logo", null)
  }
  if(layout.defaulterRules){
    formData.append('defaulter_rules', JSON.stringify(layout.defaulterRules))
  }
  if (layout.companyVideo && (layout.companyVideo instanceof Blob)) {
    formData.append("skip_video", 'no');

    let obj_name = `company_videos/companyVideo-${jobId}${layout.companyVideoExtension}`
    formData.append("company_video", obj_name)

    let obj = layout.companyVideo
    upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, obj, obj_name, token)

  } else if (layout.companyVideo === false) {
    formData.append("skip_video", 'no');
  } else {
    formData.append("skip_video", 'yes');
    formData.append("company_video", null)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/setting_layout`,
    method: 'POST',
    body: formData,
    userFetch: async (url, req) => {

      const axiosRequest = {
        ...req,
        data: req.body,
        onUploadProgress
      };
      const axiosResponse = await axios(axiosRequest);
      console.debug(axiosResponse)
      return new Response(JSON.stringify(axiosResponse.data), {
        status: axiosResponse.status,
        headers: axiosResponse.headers,
      });
    },
  };

  return Swagger.http(request)
};

function convertToSubmission(emailBody) {
  return {
    title: emailBody.title,
    message: emailBody.message,
    add_logo: emailBody.addLogo ? 'yes' : 'no',
    sms_msg: emailBody.SMSMsg ? emailBody.SMSMsg : "",
    comment: emailBody.comment ? emailBody.comment : ""
  };
}

export const setQuestion = (token, job_id, qtype) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_setting_question({
      payload: {
        job_id,
        qtype,
        token,
      }
    })
  })
};

export const setmultiQuestion = (token, job_id, multiQuestion) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_setting_multiquestion({
      payload: {
        job_id,
        multiQuestion,
        token,
      }
    })
  })
};

export const uploadQuestionFile = (token, file, filename) => {
  if (file && (file instanceof Blob)) {
    const formData = new FormData();
    formData.append("token", token);
    //formData.append("question_file", file, filename);

    let obj_name = `question_files/${filename}`
    //let obj_name = `interviewer_images/interviewerImage-${jobId}-${uuidv1()}${layout.interviewerImageExtension}`
    formData.append("question_file", obj_name) //`interviewer_images/interviewerImage-${jobId}-${uuidv1()}${layout.interviewerImageExtension}`);
    // uploading object to s3
    upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, file, obj_name, token)

    const request = {
      url: `${config.baseUrl}/api/recruiter/upload_question_file`,
      method: 'POST',
      body: formData,
    };
    return Swagger.http(request);
  }
};

export const fetchVirtualAvatars = (token, jobId) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_fetch_virtual_avatars({
      payload: {
        token,
        job_id: jobId
      }
    })
  })
}

export const setEmail = (token, jobId, email) => {
  // Tags Interface
  const formData = new FormData();
  formData.append("token", token);
  formData.append("job_id", jobId);
  // if (email.emailLogo instanceof Blob) {
  //   // formData.append("email_logo", email.emailLogo, `emailLogo-${jobId}-${uuidv1()}${email.emailLogoExtension}`);
  //   formData.append("skip_email_logo", 'no');
  // } else {
  //   formData.append("skip_email_logo", 'yes');
  // }
  formData.append("show_advance_setting", email.showAdvancedSettings)
  formData.append("report_send", email.showReport)
  formData.append("email_domain", email.emailDomain);
  var temp = email.invitation ? formData.append("invitation", JSON.stringify(convertToSubmission(email.invitation))) : formData.append("invitation", "");
  temp = email?.invitation?.attachment ? formData.append('attachment', 1) : formData.append("attachment", 0);

  if (email?.invitation?.attachment_file && email?.invitation?.attachment_file instanceof Blob) {

    let obj_name = `invitation_email_attachments/email_${uuidv1()}${email.invitation.attachment_file.name}`
    temp = formData.append("attachment_file", obj_name) //`company_videos/companyVideo-${jobId}-${uuidv1()}${layout.companyVideoExtension}`);
    let obj = email.invitation.attachment_file
    upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, obj, obj_name, token)


    //temp = formData.append("attachment_file", email.invitation.attachment_file, `${uuidv1()}${email.invitation.attachment_file.name}`)
  } else {
    temp = formData.append("attachment_file", null)
  }


  temp = email.reminder ? formData.append("reminder", JSON.stringify(convertToSubmission(email.reminder))) : formData.append("reminder", "");
  temp = email.extension ? formData.append("extension", JSON.stringify(convertToSubmission(email.extension))) : formData.append("extension", "");
  temp = email.confirmation ? formData.append("confirmation", JSON.stringify(convertToSubmission(email.confirmation))) : formData.append("confirmation", "");
  temp = email.redo ? formData.append("redo", JSON.stringify(convertToSubmission(email.redo))) : formData.append("redo", "");
  temp = email.highScoreContent ? formData.append("high_score_content", JSON.stringify(convertToSubmission(email.highScoreContent))) : formData.append("high_score_content", "");
  temp = email.mediumScoreContent ? formData.append("medium_score_content", JSON.stringify(convertToSubmission(email.mediumScoreContent))) : formData.append("medium_score_content", "");
  temp = email.lowScoreContent ? formData.append("low_score_content", JSON.stringify(convertToSubmission(email.lowScoreContent))) : formData.append("low_score_content", "");

  // console.log("formData", formData)
  const request = {
    url: `${config.baseUrl}/api/recruiter/setting_email`,
    method: 'POST',
    body: formData,
  };
  return Swagger.http(request);
};


export const setRedo = (job_id, interview_ids, token) => {
  console.log("setRedo function: ",job_id, interview_ids, token)
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_set_candidate_redo({
      payload: {
        job_id,
        interview_ids,
        token,
      }
    })
  })
};

export const deleteCandidate = (job_id, interview_ids, token) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_delete_candidate({
      payload: {
        job_id,
        interview_ids,
        token,
      }
    })
  })
};

export const deleteJobs = (jobs, token) => {
  return clientRequest.then(client => {
    //Tags Interface
    const programmeGroup = _.groupBy(jobs, 'programmeName');
    const programmes = [];
    Object.keys(programmeGroup).forEach((programmeName) => {
      programmes.push({
        program: programmeName,
        job_ids: programmeGroup[programmeName].map(job => job.jobId),
      })
    });
    return client.apis[tag].post_delete_jobs({
      payload: {
        jobs: programmes,
        token,
      }
    })
  })
};

export const setWeighting = (job_id, token, weighting) => {
  return clientRequest.then(client => {
    //  Tags Interface
    return client.apis[tag].post_setting_score({
      payload: {
        job_id,
        token,
        weighting,
      }
    })
  })
}

export const importCandidate = (candidateFile, token, job_id, startDate, endDate) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_import_candidates({
      dataFile: candidateFile,
      token: token,
      job_id: job_id,
      interview_start: startDate,
      interview_deadline: endDate
    })
  })
}

// export const importCandidateByJson = (json, token, job_id, startDate, endDate) => {
//   return clientRequest.then(client => {
//     // Tags Interface
//     return client.apis[tag].post_import_candidates_by_sjon({
//       jobseeker_json: JSON.stringify(json),
//       token: token,
//       job_id: job_id,
//       interview_start: startDate,
//       interview_deadline: endDate
//     })
//   })
// }
export const importCandidateByJson = (json, token, job_id, startDate, endDate) => {
  return clientRequest.then(client => {
    return client.apis[tag].post_import_candidates_by_sjon({
      payload: {
        token: token,
        jobseeker_json: JSON.stringify(json),
        job_id: job_id,
        interview_start: startDate,
        interview_deadline: endDate
      }
    })
  })
}

export const importCandidateByCV = (jsonList, token, job_id, startDate, endDate) => {
  return clientRequest.then(client => {
      // Tags interface
    return client.apis[tag].post_import_candidates_by_cv({
      payload: {
        token: token,
        jobseeker_list: JSON.stringify(jsonList),
        job_id: job_id,
        interview_start: startDate,
        interview_deadline: endDate
      }
    });
  })
}

export const getTask = (task_id) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].get_get_import_task({
      task_id: task_id,
    })
  })
}

export const fetchScores = (job_id, token, review_status = null, pagination = null, filter = null, sort = null) => {
  const formData = new FormData()
  formData.append('token', token)
  if (!_.isNil(job_id)) {
    formData.append('job_id', job_id)
  }
  if (!_.isNil(review_status)) {
    formData.append('review_status', review_status)
  }
  if (pagination) {
    const pageNum = pagination?.pageNum ?? 1 // page number
    const selectAll = pagination?.selectAll ?? false // select all records
    let limit = 0 // number of records per page
    if (!selectAll && pagination?.limit > 0) {
      limit = pagination?.limit ?? 0
    }

    formData.append('page', pageNum)
    formData.append('limit', limit)

  }
  if (filter) {
    const searchTerm = filter?.searchTerm ?? "" // search term
    const statusFilter = filter?.statusFilter ?? 'All' // status filter
    const precentage = filter?.percentageFilter ?? null // precentage filter
    const precentageType = filter?.percentageFilterType ?? 'auto_score' // precentage type filter
    formData.append('search_term', searchTerm)
    formData.append('filter_status', statusFilter)
    if (precentage){
      formData.append('filter_percentage', precentage)
      formData.append('filter_percentage_type', precentageType)
    }
  }
  if (sort) {
    const sortBy = sort?.sortBy ?? "name" // column name to sort based on db field keys
    const order = sort?.order ?? 1 // 1 asc or -1 desc
    formData.append('sort', sortBy)
    formData.append('order', order)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/fetch_scores`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}

/**
 * Used Primarily within the Interview Review Dashboard to view all
 * current candidates ready to be manually reviewed
 * 
 * @param {*} job_id 
 * @param {*} token 
 * @param {*} review_status 
 * @returns 
 */
export const fetchCandidatesForManualReviewStatus = (token, pagination = null, filter = null, sort = null) => {
  const formData = new FormData()
  formData.append('token', token)

  if (pagination) {
    const pageNum = pagination?.pageNum ?? 1 // page number
    const selectAll = pagination?.selectAll ?? false // select all records
    let limit = 0 // number of records per page
    if (!selectAll && pagination?.limit > 0) {
      limit = pagination?.limit ?? 0
    }

    formData.append('page', pageNum)
    formData.append('limit', limit)
  }

  if (filter) {
    const searchField = filter?.searchField ?? 'interview_id' // status field
    const searchTerm = filter?.searchTerm ?? "" // search term
    formData.append('search_field', searchField)
    formData.append('search_term', searchTerm)
  }

  if (sort) {
    const sortBy = sort?.sortBy ?? "last_interview" // column name to sort based on db field keys
    const order = sort?.order ?? -1 // 1 asc or -1 desc
    formData.append('sort', sortBy)
    formData.append('order', order)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/fetch_candidates_for_manual_review`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}

export const fetchSetting = (job_id, token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_fetch_setting({
      payload: {
        job_id,
        token,
      }
    })
  })
}

export const fetchScoresByGuest = (token, review_status = null) => {
  const formData = new FormData()
  formData.append('token', token)
  // if (!_.isNil(job_id)) {
  //   formData.append('job_id', job_id)
  // }
  if (!_.isNil(review_status)) {
    formData.append('review_status', review_status)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/fetch_scores`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}

export const fetchSettingByGuest = (token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_fetch_setting_by_invitation_token({
      payload: {
        token,
      }
    })
  })
}

export const fetchJobInfoByGuest = (token) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_fetch_job_by_invitation_token({
      payload: {
        token,
      }
    })
  })
}

export const fetchStatus = (token) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_recruiter_fetch_status({
      payload: {
        token,
      }
    })
  })
}




/**
 * API Wrapper to fetch recruiter status (doc object) by a guest reviewer 
 * invitation token.
 * 
 * Update: 23/12/2022
 * 1. When guest token is valid, Returns object of type: 
 * `{"error_code": 0, "result": {"display_emotion": 1,"video_annotation_required": 0}`
}
 * @param {*} token 
 * @returns 
 */
export const fetchStatusByInvitationToken = (token) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_recruiter_fetch_status_by_invitation_token({
      payload: {
        token,
      }
    })
  })
}

export const changeCandidateStatus = (token, job_id, interview_ids, is_shortlist) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_change_jobseeker_status({
      payload: {
        token,
        is_shortlist,
        job_id,
        interview_ids,
      }
    })
  })
}
export const changeCandidateDeadline = (token, interview_deadline, job_id, interview_ids, send_notice, send_invitation) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_change_jobseeker_deadline({
      payload: {
        interview_deadline,
        interview_ids,
        send_notice,
        send_invitation,
        token,
        job_id
      }
    })
  })
}

export const getUsage = (token) => {
  return clientRequest.then(client => {
    //Tags Interface
    return client.apis[tag].post_get_usage({
      payload: {
        token
      }
    })
  })
}

export const __getPurchases = (token, start = null, limit = null) => {
  const formData = new FormData()
  formData.append('token', token)
  if (!_.isNil(start)) {
    formData.append('start', start)
  }
  if (!_.isNil(limit)) {
    formData.append('limit', limit)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/get_purchases`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}
export const getPurchases = moize(__getPurchases, {
  maxAge: 5 * 60 * 1000
})


export const __getPurchaseUsages = (token, start = null, limit = null) => {
  const formData = new FormData()
  formData.append('token', token)
  if (!_.isNil(start)) {
    formData.append('start', start)
  }
  if (!_.isNil(limit)) {
    formData.append('limit', limit)
  }
  const request = {
    url: `${config.baseUrl}/api/recruiter/get_purchase_usages`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}
export const getPurchaseUsages = moize(__getPurchaseUsages, {
  maxAge: 5 * 60 * 1000
})

export const inviteEvaluation = (token, candidates, reviewers, message, job_id) => {
  // console.log(tag)
  return clientRequest.then(client => client.apis[tag].post_invite_evaluation({
    payload: {
      token,
      interview_ids: candidates,
      job_id,
      reviewers,
      message
    }
  }))
}


export const __getPurchaseBalance = (token,group) => {
  return clientRequest.then(client => client.apis[tag].post_get_purchase_balance({
    payload: {
      token,
      group
    }
  }))
}
export const getPurchaseBalance = moize(__getPurchaseBalance, {
  maxAge: 5 * 60 * 1000
})

export const getRemainingQuota = token => {
  return clientRequest.then(client => client.apis[tag].post_get_remaining_quota({
    payload: {
      token,
    }
  }))
}

export const getCustomizedEvaluationApi = (token, job_id) => {
  const res =  clientRequest.then(client => client.apis[tag].post_get_customized_evaluation({
    payload: {
      token,
      job_id,
    }
  }))
  return res
}

export const getCustomizedEvaluationByGuestApi = (token) => {
  return clientRequest.then(client => client.apis[tag].post_get_customized_evaluation_by_invitation_token({
    payload: {
      token,
    }
  }))
}

export const setCustomizedEvaluationApi = (token, job_id, setting) => {
  return clientRequest.then(client => client.apis[tag].post_set_customized_evaluation({
    payload: {
      token,
      job_id,
      setting,
    }
  }))
}


export const redoQuestionsApi = (token, jobId, interview_id, questions, needEmail) => {
  return clientRequest.then(client => client.apis[tag].post_redo_questions({
    payload: {
      token,
      job_id: jobId,
      interview_id,
      questions,
      need_email: needEmail,
    }
  }))
}


export const fetchOngoingInterviewApi = (token) => {
  return clientRequest.then(client => client.apis[tag].post_fetch_ongoing_interview({
    payload: {
      token,
    }
  }))
}

export const sync_candidate_from_sap = (token) => {
    const formData = new FormData()
    formData.append('token', token)
  
  const request = {
    url: `${config.baseUrl}/api/recruiter/sync_candidates_from_SAP`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}

export const sync_job_from_sap = (token) => {
  const formData = new FormData()
  formData.append('token', token)

  const request = {
    url: `${config.baseUrl}/api/recruiter/sync_job_from_SAP`,
    method: 'POST',
    body: formData,
  }
  return Swagger.http(request)
}
/**
 * Magic Link API endpoints
 */

/**
 * Generate URL Hash for the magic links / qr code
 * @param {string} token 
 * @param {string} jobId 
 * @returns 
 */
export const createURLHash = (token, jobId) => {
  return clientRequest.then( client => client.apis[tag].post_create_url_hash({
    payload: {
      token: token,
      job_id: jobId,
    }
  }))
}

/**
 * Store date for magic link
 * @param {string} token 
 * @param {string} jobId 
 * @param {string} configs 
 * @returns 
 */
export const createMagicLinkData = (token, jobId, configs) => {
  return clientRequest.then( client => client.apis[tag].post_create_update_magic_link_data({
    payload: {
      token: token,
      job_id: jobId,
      configs: configs,
    }
  }))
}

/**
 * Fetch Magic Link Data
 * @param {string} token 
 * @param {string} jobId 
 * @returns 
 */
export const fetchMagicLinkData = (token, jobId) => {
  return clientRequest.then( client => client.apis[tag].post_fetch_magic_link_data({
    payload: {
      token: token,
      job_id: jobId,
    }
  }))
}

export const setConsent = (token, jobId, consentContent) => {
  return clientRequest.then(client => client.apis[tag].post_create_consent_data({
    payload: {
      token,
      job_id: jobId,
      consent_content: consentContent
    }
  }))
}

export const getConsent = (token, jobId) => {
  return clientRequest.then(client => client.apis[tag].post_fetch_consent_data_recruiter({
    payload: {
      token,
      job_id: jobId
    }
  }))
}

export const getAllCompetencyFullModel = () => {
  return clientRequest.then(client => client.apis[tag].get_fetch_competency_full_model())
}

export const getCustomizeCompetencyList = (token, customizeCompetencyCoupon) => {
  return clientRequest.then(client => client.apis[tag].post_fetch_customize_competency_list({
    payload: {
      token: token,
      customizeCompetencyCoupon: customizeCompetencyCoupon,
    }
  }))
}

export const shareReportWithCandidate =(token, candidateData, jobid) =>{
  return clientRequest.then(client => client.apis[tag].post_send_bulkemail_candbulk_candidates({
    payload: {
      token,
      candidateData: candidateData,
      jobid:jobid
    }
  }))}

  
export const fetchCompletedAssessmentList = (token) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_fetch_completed_list_recruiter({
      payload: {
        token: token
      }
    })
  })
}

export const exportCompletedAssessmentList = (token) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_export_completed_list_recruiter({
      payload: {
        token: token
      }
    })
  })
}

/**
 * Assessment Generation API Endpoints
 */
export const createKnowledgeUploadUrl = (token, inputs) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_create_knowledge_upload_url({
      payload: {
        token,
        ...inputs
      }
    })
  })
}
export const generateAssessment = (token, inputs) => {
  return clientRequest.then((client) => {
    return client.apis[tag].post_generate_assessment({
      payload: {
        token,
        ...inputs
      }
    })
  })
}

const recruiter = {
  login,
  logout,
  action,
  sapStatusChangeAPI,
  sapStatusResetAPI,
  reportAction,
  setLayout,
  setQuestion,
  setEmail,
  setWeighting,
  uploadQuestionFile,
  addProgram,
  changePassword,
  fetchStatus,
  fetchStatusByInvitationToken,
  fetchSetting,
  getTask,
  changeCandidateStatus,
  updateInformation,
  changeCandidateDeadline,
  updateCandidateInformation,
  updateAccountInformation,
  verify_sap_account_api,
  getUsage,
  getRemainingQuota,
  getPurchases,
  getPurchaseUsages,
  getPurchaseBalance,
  resetPassword,
  forgetPassword,
  getCustomizedEvaluationApi,
  setCustomizedEvaluationApi,
  inviteEvaluation,
  redoQuestionsApi,
  updateAnnotationApi,
  getCustomizedEvaluationByGuestApi,
  fetchOngoingInterviewApi,
  sync_candidate_from_sap,
  sync_job_from_sap,
  setConsent,
  getConsent,
  getAllCompetencyFullModel,
  getCustomizeCompetencyList,
  shareReportWithCandidate,
  generateRecruiterAPIKey,
};

export default recruiter;