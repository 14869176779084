import React, {useState, useEffect, useRef, createContext, useContext, useMemo } from "react";
import { User, UserManager, UserManagerSettings, WebStorageStateStore, InMemoryWebStorage } from "oidc-client-ts";
import { AuthProvider, useAuth } from "react-oidc-context";

import { neufastTenant } from "./neufastTenant";
import { kbtgTenant } from "./kbtgTenant";
import { Domain } from "@material-ui/icons";

const OpenIdInstanceContext = createContext();

export const OPENID_STATE = {
    "INITIALIZE": "initialize",
    "LOADING": "loading",
    "READY": "ready"
}

const defaultConfigs = {
    scope: "profile openid email",
    response_type: "code",
    onSigninCallback:  (_user) => {
        console.log("Auth System: user: ", _user)
    },
    monitorSession: true,
    monitorAnonymousSession: true,
    revokeTokensOnSignout: true,
    // userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export const OpenIdProvider = ({children}) => {
    // const oidcConfigRef = useRef(JSON.parse(localStorage.getItem("oidc_config")))
    const oidcConfigRef = useRef(null)
    // const currentTenantRef = useRef(localStorage.getItem("odic_tenant") || "neufast")
    const currentTenantRef = useRef("neufast")
    console.log("Auth System: Default Check: authority: useRef:", oidcConfigRef.current?.authority)
    /**
     * 
     */
    const [state, setState] = useState(OPENID_STATE.INITIALIZE)

    // const currentTenantRef = useRef("neufast")
    
    const setCurrentTenant = (value) => currentTenantRef.current = value

    if([null, undefined].includes(oidcConfigRef.current)){
        setState(prev => OPENID_STATE.LOADING)
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...neufastTenant
        }
        // localStorage.setItem("oidc_config", JSON.stringify(oidcConfigRef.current))
        // localStorage.setItem("odic_tenant", currentTenantRef.current)
        console.log("Auth System: Default Check: currentTenantRef", currentTenantRef.current, " oidcConfigRef.current: ", oidcConfigRef.current?.authority)
    }

    const getConfigs = (tenantName) => {
        let newConfig;
        switch(tenantName){
            case 'kbtg':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            case 'sap':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            default:
                console.log("OpenIdProvider: defaulting to neufast tenant")
                newConfig = neufastTenant
                setCurrentTenant("neufast")
        }
        return newConfig;
    }

    const changeTenant = (tenantName) => {
        console.log("Auth System: LoginPage: changing tenant: ", tenantName)
        setState(prev => OPENID_STATE.LOADING)
        let newConfig = getConfigs(tenantName);
        // switch(tenantName){
        //     case 'kbtg':
        //             newConfig = kbtgTenant;
        //             setCurrentTenant(tenantName)
        //             break;
        //     case 'sap':
        //             newConfig = kbtgTenant;
        //             setCurrentTenant(tenantName)
        //             break;
        //     default:
        //         console.log("OpenIdProvider: defaulting to neufast tenant")
        //         newConfig = neufastTenant
        //         setCurrentTenant("neufast")
        // }
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...newConfig,
        }
        // localStorage.setItem("oidc_config", JSON.stringify(oidcConfigRef.current))
        // localStorage.setItem("odic_tenant", tenantName)
        console.log("Auth System: Default Check: changeTenant authority:", oidcConfigRef.current?.authority, " tenantName: ", tenantName)
        setState(prev => OPENID_STATE.READY)
    }

    useMemo(() => {
        console.log("Auth System: Default Check: authority: ", oidcConfigRef.current?.authority)
    }, [oidcConfigRef.current?.authority])


    return (
        <>
            <OpenIdInstanceContext.Provider value={{
                oidcConfig: oidcConfigRef.current,
                OPENID_STATE,
                state,
                currentTenant: currentTenantRef.current,
                getConfigs,
                changeTenant,
            }}>
                <AuthProvider {...oidcConfigRef.current} key={currentTenantRef.current}>
                {/* <AuthProvider {...oidcConfigOld}> */}
                    {children}
                </AuthProvider>
            </OpenIdInstanceContext.Provider>
            </>
    )
    // return useMemo(() => {
    //     console.log("Auth System: refreshed: oidcConfigRef.current: ", oidcConfigRef.current?.authority, " currentTenantRef: ", currentTenantRef.current, " domain: ", state)
    //     return (
    //         <>
    //         <OpenIdInstanceContext.Provider value={{
    //             oidcConfig: oidcConfigRef.current,
    //             OPENID_STATE,
    //             state,
    //             currentTenant: currentTenantRef.current,
    //             changeTenant,
    //         }}>
    //             <AuthProvider {...oidcConfigRef.current} key={currentTenantRef.current}>
    //             {/* <AuthProvider {...oidcConfigOld}> */}
    //                 {children}
    //             </AuthProvider>
    //         </OpenIdInstanceContext.Provider>
    //         </>

    //     )
    // }, [oidcConfigRef.current])
}

export const useOpenId = () => {
    const context = useContext(OpenIdInstanceContext);
    if(!context){
        throw new Error('useOpenId must be used within an OpenIdProvider provider');
    }
    return context;
}