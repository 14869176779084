import { createSlice } from "redux-starter-kit";
import { setPagination } from "./candidateList";
import { sortBy } from "lodash";

/**
 * @template
 */
let manualReviewInitialState = {
    manualReviewPagination: {
        pageNum: 1,
        limit: 10,
        SelectAll: false,
        fetching: false,
        filter: {
            searchField: "interview_id",
            searchTerm: "",
        },
        sort: {
            sortBy: 'id',
            order: -1,
        }
    }
}

const paginationSlice = createSlice({
    slice: 'paginationSlice',
    initialState: manualReviewInitialState,
    reducers: {
        onResetManualReviewPagination(state, action){
            const returnPagination = {
                ...state,
                ...manualReviewInitialState
            }
            return returnPagination;
        },
        setManualReviewPagination(state, action){
            const {
                pageNum,
                limit,
                selectAll,
                fetching,
                filter,
                sort,
            } = action.payload;
            const returnPagination = {
                ...state,
                manualReviewPagination: {
                    ...state.manualReviewPagination,
                    pageNum,
                    limit,
                    selectAll: selectAll !== undefined ? selectAll : state.manualReviewPagination.selectAll,
                    fetching: fetching !== undefined ? fetching : true,
                    filter: {
                        ...state.manualReviewPagination.filter,
                        searchField: filter.searchField ?? state.manualReviewPagination.filter.searchField,
                        searchTerm: filter.searchTerm ?? state.manualReviewPagination.filter.searchTerm,
                    },
                    sort: {
                        ...state.manualReviewPagination.sort,
                        sortBy: sort.sortBy ?? state.manualReviewPagination.sort.sortBy,
                        order: sort.order ?? state.manualReviewPagination.sort.order,
                    }
                },
            }
            return returnPagination;
        }
    }
})

export const {
    setManualReviewPagination,
    onResetManualReviewPagination
} = paginationSlice.actions;

export default paginationSlice.reducer;