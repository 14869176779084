import { loginRequest, graphConfig} from '../config/authConfig.dev';
import { msalInstance } from '../App';
import axios from 'axios';
import {
    InteractionRequiredAuthError
} from '@azure/msal-browser';

export async function callMsGraph(accessToken){
    if(!accessToken){
        let accounts = msalInstance.getAllAccounts()
        let account = msalInstance.getActiveAccount()
        // if (!account && accounts.length > 0){
        //     console.log("Setting active account to first available account")
        //     account = msalInstance.setActiveAccount(accounts[0])
        // }
        console.log("SsoLogin: account: ", account, " allAccounts: ", accounts)
        if (!account) {
            // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.")
            // account=accounts[0]
        }
        console.log("SsoLogin: retrieving access token")
        let response=null
        try{
            response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
        } catch (error){
            console.error(error)
            if (error instanceof InteractionRequiredAuthError){
                response = await msalInstance.acquireTokenRedirect({
                    ...loginRequest,
                    account: account
                });
            } else return {
                return_val: null,
                return_token: accessToken,
            }
        }
        accessToken = response.accessToken;
        console.log("AccessToken Response: ", accessToken)
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    // return fetch(graphConfig.graphMeEndpoint, options)
    //     .then(async response => {
    //         const responseJson = await response.json()
    //         console.log("SsoLogin: calling api: email: ", response)
    //         return {
    //             return_val: responseJson,
    //             return_token: accessToken,
    //         }
    //     })
    //     .catch(error => console.error(error));
    return axios({
        method: 'get',
        url: graphConfig.graphMeEndpoint,
        headers: headers
    })
    .then(response => {
            const responseJson = response.data
            console.log("SsoLogin: calling api: email: ", response)
            return {
                return_val: responseJson,
                return_token: accessToken,
            }
        })
    .catch(error => console.error(error));
}