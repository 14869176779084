import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, LinearProgress, Button, Alert } from "@mui/material";

const SSOLoadingScreen = ({ onRetry }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Simulate SSO progress
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          setLoading(false);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    // Simulate an SSO process that could fail
    const fakeSSOProcess = setTimeout(() => {
      // Simulate failure with 30% probability
      if (Math.random() < 0.3) {
        clearInterval(timer);
        setLoading(false);
        setError(true);
      }
    }, 3000);

    return () => {
      clearInterval(timer);
      clearTimeout(fakeSSOProcess);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Signing you in...
      </Typography>

      {loading && (
        <>
          <CircularProgress />
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </>
      )}

      {!loading && error && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            SSO login failed. Please try again.
          </Alert>
          <Button variant="contained" color="primary" onClick={onRetry}>
            Retry
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SSOLoadingScreen;
