import { create_s3_upload_url_simple, gets3object } from '../apis/s3access'
import axios from 'axios'


export const upload_single_object_to_s3 = async (bucket_name, obj, object_name, token, content_type = null) => {

    try {
        console.log("upload_single_object_to_s3: object_name: ", object_name, " file data: ", obj)
        const res = await create_s3_upload_url_simple(token, bucket_name, object_name, obj)
        if (res.error_code === 0) {
            const formData = new FormData()
            Object.entries(res.msg.fields).forEach(([key, values]) => {
                formData.append(key, values);
            })
            formData.append('Content-Type', obj.type)
            formData.append('file', obj)
            try {
                const response = await axios.post(res.msg.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                console.log("upload_single_object_to_s3: response: ", response)
                return response
            } catch (err) {
                console.log("upload_single_object_to_s3: post request error")
                return null
            }
        } else {
            console.log("upload_single_object_to_s3")
            return null
        }
    } catch (err) {
        console.log("upload_single_object_to_s3: error: ", err)
        return null
    }
}





export const get_interviewerImage = async (object_name, token, bucket_name) => {
    const res = await gets3object(object_name, token, bucket_name)
    return res
}



