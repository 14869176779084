import clientRequest from '../constants/clientRequest'

const tag = 'api/group'

export const createGroup = (token, groupName, admin, recruiter) => {
  return clientRequest.then(client => {
    console.log(client)
    return client.apis[tag].post_create_group({
      payload: {
        token,
        groupName,
        admin,
        recruiter
      }
    })
  })
}

export const fetchGroups = token => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_fetch_group({
      payload: {
        token
      }
    })
  })
}

export const fetchRecruiter = (token, gid) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_fetch_recruiter({
      payload: {
        token,
        gid
      }
    })
  })
}

export const extract_token = token => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_extract_token({
      payload: {
        token
      }
    })
  })
}

export const register_by_token = (token, first_name, last_name, password) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_regis_recruiter({
      payload: {
        token,
        first_name,
        last_name,
        password
      }
    })
  })
}

export const add_recruiter = (token, email, role, gid) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_add_recruiter({
      payload: {
        token,
        email,
        role,
        gid
      }
    })
  })
}

export const edit_recruiter = (token, gid, email, oldRole, newRole) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_edit_recruiter({
      payload: {
        token,
        gid,
        email,
        oldRole,
        newRole
      }
    })
  })
}

export const confirm_status = (token, gid, option) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_confirm_status({
      payload: {
        token,
        gid,
        option
      }
    })
  })
}

export const fetch_group_program = token => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_fetch_group_program({
      payload: {
        token
      }
    })
  })
}

export const delete_recruiter = (token, email, gid, role) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_delete_recruiter_from_group({
      payload: {
        token,
        email,
        gid,
        role
      }
    })
  })
}

export const delete_group = (token, gid) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_delete_group({
      payload: {
        token,
        gid,
      }
    })
  })
}

export const edit_group = (token, gid, groupName, developerModeEnabledBool) => {
  return clientRequest.then(client => {
    const developerModeEnabled = developerModeEnabledBool ? 1 : 0
    // Tags interface
    return client.apis[tag].post_edit_group({
      payload: {
        token,
        gid,
        groupName,
        developerModeEnabled,
      }
    })
  })
}


export const generateGroupAPIKey = (token, gid) => {
  return clientRequest.then(client => {
    // Tags Interface
    return client.apis[tag].post_generate_group_api_key({
      payload: {
        token,
        gid,
      }
    })
  })
}