import React from 'react'
import PropTypes from "prop-types";
import { 
    Box, 
    Typography, 
    Button, 
    IconButton, 
    Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Logout = ({ history }, context) => {

    const useStyles = makeStyles(theme => ({
        title: {
            fontSize: 20,
            fontWeight: 900,
            color: '#474747',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: 30,
            WebkitTextStroke: '1px #474747',
            textStroke: '1px #474747',
            textShadow: '0 0 1px #474747, 0 0 1px #474747',
            letterSpacing: '2px',
        },
        subtitle: {
            fontSize: 20,
            fontWeight: theme.typography.fontWeightBold,
            color: '#395a64',
            WebkitTextStroke: '1px #395a64',
            textStroke: '1px #395a64',
            textShadow: '0 0 1px #395a64, 0 0 1px #395a64',
        },
        backButton: {
            backgroundColor: '#f3f3f3',
            borderColor: '#fafafa',
            textTransform: 'none',
            fontSize: '16px'
        }
    }))

    const classes = useStyles();

    const BackButton = styled(Button)(({ theme }) => ({
        backgroundColor: '#f3f3f3',
        borderColor: '#fafafa',
        color: '#606567',
        textTransform: 'none',
        fontSize: '16px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#f3f3f3',
            borderColor: '#fafafa',
            color: '#606567',
        }
    }));

    const handleBack = () => {
        const currentLoggedOutUserType = sessionStorage.getItem('currentLoggedOutUserType');
        history.push(`/${currentLoggedOutUserType}`)
        sessionStorage.removeItem('currentLoggedOutUserType');
    }
    
    const handleClickLinkedIn = () => {
        window.location = 'https://www.linkedin.com/company/neufast/';
    }

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginBottom: '15vh'}}>
                {/* <Typography variant="h2" className={classes.title}>
                    {context.t("Logout_Title")}
                </Typography> */}
                <img src={"/logo/Neufast_logo_horizontal.png"} alt="logout" style={{ width: '20%' }}></img>
            </Box>
            <Grid container spacing={6} justifyContent={'space-evenly'}>
                <Grid item xs={6}>
                    <Box sx={{paddingLeft: '28vw', paddingTop: '7vh'}}>
                        <Typography variant='h4' className={classes.subtitle} sx={{marginBottom: '16px' }}>
                            {context.t("Logout_LoggedOut")}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', color: '#131313' }}>
                            {context.t("Logout_QuestionsOrAssitance")}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', marginBottom: '16px', color: '#131313' }}>
                            {context.t("Logout_FeelFreeTo")}<a href="mailto:customer-service@neufast.com" style={{ color: '#4388e2' }}>{context.t("Logout_ContactUs")}</a>.
                        </Typography>
                        <Typography sx={{ fontSize: '16px', marginBottom: '16px' }}>
                            {context.t("Logout_LetsConnect")}
                            <IconButton onClick={ handleClickLinkedIn}>
                                <LinkedInIcon sx={{ color: '#022d41', fontSize: '20px'}}/>
                            </IconButton>
                        </Typography>
                        <BackButton variant="contained" onClick={handleBack}>
                            {context.t("Logout_BackToHome")}<ArrowForwardIcon/>
                        </BackButton>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <img src={"/images/logout_thankyou.png"} alt="thankyou" style={{ width: '40%' }}></img>
                </Grid>
            </Grid>
        </Box>
    )
}

Logout.contextTypes = {
    t: PropTypes.func
};

export default Logout