import Swagger from 'swagger-client'
import config from '../config'

const swaggerSpec = `${config.baseUrl}/swagger.json`
const clientRequest = Swagger({
    url: swaggerSpec,
    requestInterceptor: (req) => {
        req.credentials = "include";
        req.mode = 'cors';
        // req.headers[""]
        return req;
    },
    enableCORS: true,
    withCredentials: true
}).then(client => {
        if (process.env.NODE_ENV === 'test') {
            client.spec.host = 'localhost:9003';
        }
        return client;
    });

export default clientRequest