import clientRequest from "../constants/clientRequest"

const tag = 'api/resume';

export const loadCV = (candidateEmail, jobId, token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_load_profile({
                payload: {
                    jobseeker_email: candidateEmail,
                    job_id: jobId,
                    token,
                }
            });
        })
};

export const saveCV = (candidateEmail, jobId, token, cv) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_submit_profile({
                payload: {
                    ...cv,
                    jobseeker_email: candidateEmail,
                    job_id: jobId,
                    token,
                }
            });
        })
};

export const parseCV = async(token, file, options, job_id) => {
    return clientRequest
        .then(client => {
            console.log("parseCV: token: ", token, " file: ", file, " options: ", options, " job_id: ", job_id)
            return client.apis[tag].post_hrflow_cv_parser({
                file,
                options,
                token,
                job_id,
            })
        })
        .catch(error => {
            if(error.name === 'AbortError'){
                console.log(`The fetch request was cancelled`)
            } else {
                console.log(`An error has occured`, error)
            }
        })
}


const resume = {
    loadCV,
    saveCV,
};

export default resume;
