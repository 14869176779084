export const neufastTenant = {
    client_id: "d2feea91-f789-4eee-9f44-e886f618383b",
    // authority: "https://login.microsoftonline.com/91b4aa0a-3423-4fd2-891a-28b727b7eed2/oauth2/v2.0/authorize",
    // authority: "https://login.microsoftonline.com/common/oauth2/v2.0/",
    // authority: "https://login.microsoftonline.com/common",
    authority: "https://login.microsoftonline.com/common/v2.0/",
    redirect_uri: "http://localhost:3000/openid/redirect/neufast",
    // metadata: {
    //     authorization_endpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    //     token_endpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
    //     revocation_endpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/revoke",
    //   },
}