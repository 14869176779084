export const translations = {
  'en': require('./translation.en.js').translation,
  'tc': require('./translation.tc.js').translation,
  'sc': require('./translation.sc.js').translation,
  'th': require('./translation.th.js').translation,
  'en_color': require('./translation.en_color.js').translation,
  'ja':require('./translation.ja.js').translation,
  'id': require('./translation.id.js').translation,
  'ms': require('./translation.ms.js').translation,
  'vi': require('./translation.vi.js').translation,
  'km': require('./translation.km.js').translation,
  'de': require('./translation.de.js').translation,
  'fr': require('./translation.fr.js').translation,
  'kr': require('./translation.kr.js').translation,
  'ta': require('./translation.ta.js').translation,
  'ar': require('./translation.ar.js').translation,
  'es': require('./translation.es.js').translation,
  'pt': require('./translation.pt.js').translation,
  'hi': require('./translation.hi.js').translation,
  'it': require('./translation.it.js').translation,
  'pl': require('./translation.pl.js').translation,
  'tr': require('./translation.tr.js').translation,
}


 //['en', 'tc', 'sc', 'th', 'ja', 'id', 'ms', 'vi', 'km']